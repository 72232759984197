import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import CreditListItem from "./CreditListItem";

const CreditSection = ({ title, students, tutors, image }) => {
  function randomLine() {
    return Math.floor(Math.random() * 14) + 1;
  }

  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulStudent(filter: { specialism: { eq: "3D Design" } }) {
            nodes {
              studentName
              slug
            }
          }
        }
      `}
      render={(data) => (
        <div className="creditSection">
          <div className="creditsTitleContainer">
            <hr />
            <h1 className="creditsTitle">Big Build</h1>
            <hr />
          </div>
          <StaticImage
            src="../assets/credits/big-build-portrait.png"
            alt="A dinosaur"
            className="creditsImage"
          />
          <div className="creditsList">
            {data.allContentfulStudent.nodes.map((student) => (
              <CreditListItem
                key={student.studentName}
                name={student.studentName}
                slug={student.slug}
                line={randomLine()}
                role="Builder"
              />
            ))}
          </div>
        </div>
      )}
    ></StaticQuery>
  );
};
export default CreditSection;
