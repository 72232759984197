import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import CreditListItem from "../components/CreditListItem";

const CreditSection = ({ title, students, tutors, image }) => {
  function randomLine() {
    return Math.floor(Math.random() * 14) + 1;
  }

  let studentList = students.map((student) => (
    <CreditListItem
      key={student.name}
      name={student.name}
      slug={student.slug}
      line={randomLine()}
      role={student.role}
    />
  ));

  return (
    <div className="creditSection">
      <div className="creditsTitleContainer">
        <hr />
        <h1 className="creditsTitle">{title}</h1>
        <hr />
      </div>
      {image === "flags" && (
        <StaticImage
          src="../assets/credits/flagspublications.png"
          alt="A dinosaur"
          className="creditsImage"
        />
      )}
      <div className="creditsList">{studentList}</div>
    </div>
  );
};
export default CreditSection;
