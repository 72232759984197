import React from "react";
import { Link } from "gatsby";

export default function Menu({ highlighted }) {
  return (
    <div className="topBarNav">
      <Link
        className={highlighted ? "menuLink menuActive" : "menuLink"}
        activeClassName="menuActive"
        to={`/all/students/`}
      >
        <p>Students</p>
      </Link>

      <Link
        className="menuLink"
        activeClassName="menuActive"
        to={`/all/projects/`}
      >
        <p>Projects</p>
      </Link>
      <Link
        className="menuLink"
        activeClassName="menuActive"
        to={`/all/credits/`}
      >
        <p>Credits</p>
      </Link>
    </div>
  );
}
