import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// import Students from "../components/StudentList";
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";

import CreditSection from "../../components/CreditSection";
import CreditSection3D from "../../components/3DCreditsSection";

import AllMenu from "../../components/AllMenu";
import Line1 from "../../assets/lines/line1.svg";
import Line2 from "../../assets/lines/line2.svg";
import Line3 from "../../assets/lines/line3.svg";
import Line4 from "../../assets/lines/line4.svg";
import Line5 from "../../assets/lines/line5.svg";
import Line6 from "../../assets/lines/line6.svg";
import Line7 from "../../assets/lines/line7.svg";
import Line8 from "../../assets/lines/line8.svg";
import Line9 from "../../assets/lines/line9.svg";
import Line10 from "../../assets/lines/line10.svg";
import Line11 from "../../assets/lines/line11.svg";
import Line12 from "../../assets/lines/line12.svg";
import Line13 from "../../assets/lines/line13.svg";
import Line14 from "../../assets/lines/line14.svg";
import Line15 from "../../assets/lines/line8.svg";

const Credits = ({ data }) => {
  //get each object out of node...
  const studentList = data.allContentfulStudent.edges.map((a) => a.node);

  function randomLine() {
    return Math.floor(Math.random() * 14) + 1;
  }

  let allLines = [
    Line1,
    Line2,
    Line3,
    Line4,
    Line5,
    Line6,
    Line7,
    Line8,
    Line9,
    Line10,
    Line11,
    Line12,
    Line13,
    Line14,
    Line15,
  ];

  const tutors = [
    "Claire",
    "Nat",
    "Is",
    "Mark",
    "Ellie C.",
    "Alice-Mae",
    "Dom",
    "Theo",
    "Ellie W.",
    "Mez",
    "Jala",
    "Shenice",
    "Emily",
    "Kieran",
    "Sarah",
    "Sophie",
    "Dean",
    "Mark",
    "Saskia",
    "Alix",
    "Emma",
    "Rebecca",
  ];
  return (
    <Layout>
      <Seo title={`Kingston School of Art Foundation 2021 • Credits`} />

      <div className="allTop credits">
        <AllMenu />
        <div className="creditsContainer">
          <CreditSection
            title={"2021 Show Branding"}
            students={[
              {
                name: "Jessie Collins",
                slug: "jessica-collins",
                role: "Visuals",
              },
              {
                name: "Lauren Christian",
                slug: "lauren-christian",
                role: "Visuals",
              },
              {
                name: "Lucia Spelsberg Hornsby",
                slug: "lucia-spelsberg-hornsby",
                role: "Visuals",
              },
              {
                name: "Lola Komolafe",
                slug: "oluwafunmilola-komolafe",
                role: "Visuals",
              },
              {
                name: "Alex Abrams",
                slug: "alexander-abrams",
                role: "Sound",
              },
              {
                name: "Poppy Aiken",
                slug: "poppy-aiken",
                role: "Sound",
              },
            ]}
          />
          <CreditSection
            title={"Radio"}
            students={[
              {
                name: "Joshua Cornwell",
                slug: "joshua-cornwell",
                role: "Presenter",
              },
              {
                name: "Alex Abrams",
                slug: "alexander-abrams",
                role: "Sound Design and Editing",
              },
            ]}
          />
          <CreditSection
            title={"Flags / Publications"}
            image={"flags"}
            students={[
              {
                name: "Emily-Jane Belcher",
                slug: "emily-jane-belcher",
                role: "3D",
              },
              {
                name: "Esmé Casson",
                slug: "esme-casson",
                role: "3D",
              },
              {
                name: "Elsie Coyle",
                slug: "elsie-coyle",
                role: "3D",
              },
              {
                name: "Ailsa Davies",
                slug: "ailsa-davies",
                role: "3D",
              },
              {
                name: "Lily Flynn",
                slug: "lily-flynn",
                role: "3D",
              },
              {
                name: "Tabitha Flynn",
                slug: "tabitha-flynn",
                role: "3D",
              },
              {
                name: "Elena Hornby",
                slug: "elena-hornby",
                role: "3D",
              },
              {
                name: "Freya King",
                slug: "freya-king",
                role: "3D",
              },
              {
                name: "Duncan Mcallister",
                slug: "duncan-mcallister",
                role: "3D",
              },
              {
                name: "Oliver Linnell",
                slug: "oliver-linnell",
                role: "3D",
              },
              {
                name: "Izzy Walter",
                slug: "isabel-walter",
                role: "3D",
              },
              {
                name: "Georgia Denny",
                slug: "georgia-denny",
                role: "Fashion",
              },
              {
                name: "Isobel Dickens",
                slug: "isobel-dickens",
                role: "Fashion",
              },
              {
                name: "Sophia Laycock",
                slug: "sophia-laycock",
                role: "Fashion",
              },
              {
                name: "Amy O'Mara",
                slug: "amy-omara",
                role: "Fashion",
              },
              {
                name: "Emily Allen",
                slug: "emily-allen",
                role: "Fine art",
              },
              {
                name: "Yuri Choi",
                slug: "yuri-choi",
                role: "Fine art",
              },

              {
                name: "Maya King-Dabbs",
                slug: "maya-king-dabbs",
                role: "Fine art",
              },
              {
                name: "Connie Woods Gundry",
                slug: "constance-woods-gundry",
                role: "Fine art",
              },
              {
                name: "Aparna K Mitra",
                slug: "aparna-mitra",
                role: "Fine art",
              },
              {
                name: "Sahara Patel",
                slug: "sahara-patel",
                role: "Fine art",
              },
              {
                name: "Phoebe Russell",
                slug: "phoebe-russell",
                role: "Fine art",
              },
              {
                name: "Fin Warren",
                slug: "finlay-warren",
                role: "Fine art",
              },
              {
                name: "Antonella Espino-Briones",
                slug: "antonella-espino-briones",
                role: "Vis comm",
              },
              {
                name: "Honey Ellis",
                slug: "honey-ellis",
                role: "Vis comm",
              },
              {
                name: "Ivy Wang",
                slug: "ivy-wang",
                role: "Vis comm",
              },
              {
                name: "Charlotte Zammit",
                slug: "charlotte-zammit",
                role: "Vis comm",
              },
            ]}
          />
          <CreditSection3D />
          <div className="creditSection">
            <div className="creditsTitleContainer">
              <hr />
              <h1 className="creditsTitle">Special thanks to</h1>
              <hr />
            </div>
            <div className="creditsList">
              {tutors.map((tutor, i) => (
                <li className="creditListItem" key={tutor}>
                  <span className="creditListText">{tutor}</span>
                  <div className="line small">
                    <img src={i > 14 ? allLines[i - 14] : allLines[i]} />
                  </div>
                  <span className="creditListText right">Tutor</span>
                </li>
              ))}
              <Link to="https://victorhwang.co">
                <li className="creditListItem">
                  <span className="creditListText">Victor</span>
                  <div className="line small">
                    <img src={allLines[13]} />
                  </div>
                  <span className="creditListText right">
                    Site design / code
                  </span>
                </li>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Credits;

export const pageQuery = graphql`
  {
    allContentfulStudent(sort: { order: ASC, fields: studentName }) {
      edges {
        node {
          collection {
            lineNumber
          }
          specialism
          studentName
          slug
          id
          heroImage {
            gatsbyImageData
            id
            title
          }
        }
      }
    }
  }
`;
